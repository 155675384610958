<template>
  <div>
    <h3 class="title is-3"> Termos &amp; Condições </h3>
    <h5 class="title is-5"> Última atualização em 1º de junho de 2021 </h5>
    <p>
      Ao baixar ou usar o aplicativo, estes termos irão
      se aplicam automaticamente a você - você deve certificar-se, portanto,
      que você os leia com atenção antes de usar o aplicativo. Você não é
      permissão para copiar ou modificar o aplicativo, qualquer parte do aplicativo, ou
      nossas marcas registradas de qualquer forma. Você não tem permissão para tentar
      extraia o código-fonte do aplicativo, e você também não deve tentar
      para traduzir o aplicativo para outros idiomas, ou torná-lo derivado
      versões. O próprio aplicativo e todas as marcas registradas, direitos autorais,
      direitos de banco de dados e outros direitos de propriedade intelectual relacionados
      a ele, ainda pertence a Cartera.
    </p>
    <br>
    <p>
      Cartera está empenhada em garantir que o aplicativo seja
      tão útil e eficiente quanto possível. Por esse motivo, nós
      reserva-se o direito de fazer alterações no aplicativo ou cobrar por
      seus serviços, a qualquer momento e por qualquer motivo. Nós nunca iremos
      cobrar pelo aplicativo ou seus serviços sem torná-lo muito
      claro para você exatamente o que você está pagando.
    </p>
    <br>
    <p>
      O aplicativo Cartera armazena e processa dados pessoais que
      você nos forneceu, a fim de fornecer nosso
      Serviço. É sua responsabilidade manter seu telefone e
      acesso ao aplicativo seguro. Portanto, recomendamos que você faça
      não desbloquear ou fazer root em seu telefone, que é o processo de
      removendo as restrições e limitações de software impostas pelo
      sistema operacional oficial do seu dispositivo. Poderia fazer o seu
      telefone vulnerável a malware / vírus / programas maliciosos,
      comprometer os recursos de segurança do seu telefone e isso pode significar
      que o aplicativo Cartera não funcionará corretamente ou de forma alguma.
    </p>
    <br>
    <p>
      O aplicativo usa serviços de terceiros que declaram seus próprios
      Termos e Condições.
    </p>
    <br>
    <p>
      Link para os Termos e Condições de provedores de serviços terceirizados usados pelo aplicativo:
      <b-link> https://policies.google.com/terms </b-link>
    </p>
    <br>
    <p>
      Você deve estar ciente de que existem certas coisas que
      Cartera não se responsabiliza por. Certo
      funções do aplicativo exigirão que o aplicativo tenha um ativo
      conexão de internet. A conexão pode ser Wi-Fi ou fornecida
      por seu provedor de rede móvel, mas Cartera
      não pode assumir a responsabilidade pelo aplicativo não funcionar totalmente
      funcionalidade se você não tem acesso a Wi-Fi, e você não
      você ainda tem alguma cota de dados sua.
    </p>
    <br>
    <p>
      Se estiver usando o aplicativo fora de uma área com Wi-Fi, você
      deve se lembrar que os termos do acordo com o seu
      provedor de rede móvel ainda será aplicável. Como resultado, você pode
      ser cobrado por sua operadora de celular pelo custo dos dados para
      a duração da conexão ao acessar o aplicativo, ou
      outras cobranças de terceiros. Ao usar o aplicativo, você está aceitando
      responsabilidade por quaisquer cobranças, incluindo dados em roaming
      encargos se você usar o aplicativo fora de seu território
      (ou seja, região ou país) sem desligar o roaming de dados. Se
      você não é o pagador de contas do dispositivo em que está
      usando o aplicativo, esteja ciente de que presumimos que você tenha
      recebeu permissão do pagador de contas para usar o aplicativo.
    </p>
    <br>
    <p>
      Na mesma linha, Cartera nem sempre pode levar
      responsabilidade pela maneira como você usa o aplicativo, ou seja, você precisa
      certifique-se de que seu dispositivo permanece carregado - se ficar sem
      bateria e você não pode ligá-la para aproveitar o Serviço,
      Cartera não pode aceitar responsabilidade.
    </p>
    <br>
    <p>
      Com relação à responsabilidade de Cartera por seu
      uso do aplicativo, quando você estiver usando o aplicativo, é importante
      tenha em mente que, embora nos esforcemos para garantir que seja
      atualizados e corretos em todos os momentos, contamos com terceiros
      para nos fornecer informações para que possamos disponibilizá-las
      para você. Cartera não se responsabiliza por qualquer
      perda, direta ou indireta, que você experimenta como resultado de
      contando totalmente com esta funcionalidade do aplicativo.
    </p>
    <br>
<p>
      Em algum momento, podemos desejar atualizar o aplicativo. O app é
      atualmente disponível em Android, iOS e &amp; KaiOS - os requisitos para
      sistema (e para quaisquer sistemas adicionais que
      decidir estender a disponibilidade do aplicativo para) pode mudar,
      e você precisará baixar as atualizações se quiser manter
      usando o aplicativo. Cartera não promete que
      sempre atualizará o aplicativo para que seja relevante para você
      e / ou funciona com o Android, iOS & &amp; Versão KaiOS que você tem
      instalado no seu dispositivo. No entanto, você promete sempre
      aceitar atualizações para o aplicativo quando oferecido a você, podemos
      também deseja interromper o fornecimento do aplicativo e pode encerrar o uso de
      a qualquer momento, sem aviso prévio de rescisão.
      A menos que lhe digamos o contrário, após qualquer rescisão, (a) o
      os direitos e licenças concedidos a você nestes termos serão encerrados;
      (b) você deve parar de usar o aplicativo e (se necessário) excluí-lo
      do seu dispositivo.
    </p>
    <br>
    <p> <strong> Mudanças nestes Termos e Condições </strong> </p>
    <br>
    <p>
      Podemos atualizar nossos Termos e Condições
      de tempos em tempos. Portanto, recomendamos que você analise esta página
      periodicamente para quaisquer alterações. Nós vamos
      notificá-lo de quaisquer alterações, publicando os novos Termos e
      Condições nesta página.
    </p>
    <br>
    <p>
      Estes termos e condições são válidos a partir de 2021-06-01
    </p>
    <br>
    <p> <strong> Entre em contato </strong> </p>
    <br>
    <p>
      Se você tiver alguma dúvida ou sugestão sobre nosso
      Termos e Condições, não hesite em nos contatar
      em contato@cartera.com.br.
    </p>
    <br>
  </div>
</template>
