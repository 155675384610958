<template>
  <div>
  <h3 class="title is-3">
  Política de Privacidade
  </h3>

  <br><h5 class="title is-5"> Última atualização em 1º de junho de 2021 </h5>

  <p>
  Obrigado por escolher fazer parte da nossa comunidade na Cartera ("Compania", "nós", "nosso"). Temos o compromisso de proteger suas informações pessoais e seu direito à privacidade. Se você tiver alguma dúvida ou preocupação sobre este aviso de privacidade ou sobre nossas práticas em relação às suas informações pessoais, entre em contato conosco pelo e-mail contato@cartera.com.br.
  </p>
  <br>
  <p>
  Quando você visita nosso site https://cartera.com.br (o "Site") e, de maneira mais geral, usa qualquer um dos nossos serviços (os "Serviços", que incluem o Site), agradecemos por você estar nos confiando suas informações pessoais. Nós levamos sua privacidade muito a sério. Neste aviso de privacidade, procuramos explicar da forma mais clara possível quais informações coletamos, como as usamos e quais direitos você tem em relação a elas. Esperamos que você reserve algum tempo para lê-lo com atenção, pois é importante. Se houver algum termo neste aviso de privacidade com o qual você não concorda, interrompa o uso de nossos Serviços imediatamente.
  </p>
  <br>
  <p>
  Este aviso de privacidade se aplica a todas as informações coletadas por meio de nossos Serviços (que, conforme descrito acima, inclui nosso Site), bem como, quaisquer serviços, vendas, marketing ou eventos relacionados.
  </p>
  <br>
  <p>
  Leia este aviso de privacidade com atenção, pois ele o ajudará a entender o que fazemos com as informações que coletamos.
  </p>
  <br>

  <br><h4 class="title is-4"> Índice </h4>
  <ol>
  <li> QUE INFORMAÇÕES RECOLHEMOS? </li>
  <li> COMO USAMOS SUAS INFORMAÇÕES? </li>
  <li> SUAS INFORMAÇÕES SERÃO COMPARTILHADAS COM ALGUEM? </li>
  <li> COMO TRATAMOS SEUS LOGINS SOCIAIS? </li>
  <li> POR QUANTO TEMPO MANTEMOS SUAS INFORMAÇÕES? </li>
  <li> COMO MANTEMOS SUAS INFORMAÇÕES SEGURAS? </li>
  <li> QUAIS SÃO OS SEUS DIREITOS DE PRIVACIDADE? </li>
  <li> CONTROLES PARA RECURSOS DO-NOT-TRACK </li>
  <li> OS RESIDENTES DA CALIFÓRNIA TÊM DIREITOS DE PRIVACIDADE ESPECÍFICOS? </li>
  <li> FAZEMOS ATUALIZAÇÕES PARA ESTE AVISO? </li>
  <li> COMO VOCÊ PODE NOS CONTATAR SOBRE ESTE AVISO? </li>
  <li> COMO VOCÊ PODE REVER, ATUALIZAR OU EXCLUIR OS DADOS QUE COLETAMOS DE VOCÊ? </li>
  </ol>

  <br><h4 class="title is-4"> 1. QUE INFORMAÇÕES RECOLHEMOS? </h4>

  <p> Informações pessoais que você nos divulga </p>
  <br>

  <p> Resumindo: coletamos informações pessoais que você nos fornece. </p>
  <br>

  <p> Coletamos informações pessoais que você voluntariamente nos fornece quando se registra no site, expressa interesse em obter informações sobre nós ou nossos produtos e serviços, quando participa de atividades no site (como postando mensagens em nosso fóruns online ou participando de competições, concursos ou brindes) ou de outra forma quando você entrar em contato conosco. </p>
  <br>

  <p> As informações pessoais que coletamos dependem do contexto de suas interações conosco e com o site, as escolhas que você faz e os produtos e recursos que usa. As informações pessoais que coletamos podem incluir o seguinte: </p>
  <br>

  <p> Informações pessoais fornecidas por você. Coletamos endereços de e-mail; nomes de usuário; e outras informações semelhantes. </p>
  <br>

  <p> Dados de login de mídia social. Podemos fornecer a você a opção de se registrar conosco usando os detalhes de sua conta de mídia social existente, como Facebook, Google ou outra conta de mídia social. Se você optar por se registrar desta forma, coletaremos as informações descritas na seção "COMO TRATAMOS SEUS LOGINS SOCIAIS" abaixo. </p>
  <br>

  <p> Todas as informações pessoais que você nos fornece devem ser verdadeiras, completas e precisas e você deve nos notificar sobre quaisquer alterações nessas informações pessoais. </p>
  <br>

  <br><h4 class="title is-4"> 2. COMO USAMOS SUAS INFORMAÇÕES? </h4>

  <p> Resumindo: Processamos suas informações para fins com base em interesses comerciais legítimos, no cumprimento de nosso contrato com você, em conformidade com nossas obrigações legais e / ou em seu consentimento. </p>
  <br>

  <p> Usamos as informações pessoais coletadas por meio de nosso site para uma variedade de fins comerciais descritos abaixo. Processamos suas informações pessoais para esses fins com base em nossos interesses comerciais legítimos, a fim de celebrar ou executar um contrato com você, com o seu consentimento e / ou para o cumprimento de nossas obrigações legais. Indicamos os fundamentos de processamento específicos nos quais confiamos ao lado de cada propósito listado abaixo. </p>
  <br>

  <p> Usamos as informações que coletamos ou recebemos: </p>
  <br>

  <p> Para facilitar a criação de contas e o processo de logon. Se você optar por vincular sua conta conosco a uma conta de terceiros (como sua conta do Google ou Facebook), usaremos as informações que você nos permitiu coletar desses terceiros para facilitar a criação de conta e o processo de logon para o desempenho do contrato. Consulte a seção a seguir intitulada "COMO TRATAMOS SEUS LOGINS SOCIAIS" para obter mais informações. </p>
  <br>
  <p> Para postar depoimentos. Publicamos depoimentos em nosso site que podem conter informações pessoais. Antes de publicar um depoimento, obteremos seu consentimento para usar seu nome e o conteúdo do depoimento. Se você deseja atualizar ou excluir seu depoimento, entre em contato conosco em contato@cartera.com.br e certifique-se de incluir seu nome, local do depoimento e informações de contato. </p>
  <br>

  <p> Solicite feedback. Podemos usar suas informações para solicitar comentários e contatá-lo sobre o uso de nosso site. </p>
  <br>

  <p> Para habilitar a comunicação entre usuários. Podemos usar suas informações para permitir a comunicação entre usuários, com o consentimento de cada um deles. </p>
  <br>

  <p> Para gerenciar contas de usuários. Podemos usar suas informações para fins de gerenciamento de nossa conta e mantê-la em funcionamento. </p>
  <br>

  <br><h4 class="title is-4"> 3. SUAS INFORMAÇÕES SERÃO COMPARTILHADAS COM ALGUEM? </h4>

  <p> Resumindo: só compartilhamos informações com o seu consentimento, para cumprir as leis, para lhe fornecer </p>
  <br> serviços, para proteger seus direitos ou para cumprir obrigações comerciais.

  <p> Podemos processar ou compartilhar seus dados que possuímos com base na seguinte base legal: </p>
  <br>

  <p> Consentimento: podemos processar seus dados se você nos der consentimento específico para usar suas informações pessoais para uma finalidade específica. </p>
  <br>

  <p> Interesses legítimos: podemos processar seus dados quando for razoavelmente necessário para atingir nossos interesses comerciais legítimos. </p>
  <br>

  <p> Execução de um contrato: quando celebramos um contrato com você, podemos processar suas informações pessoais para cumprir os termos de nosso contrato. </p>
  <br>

  <p> Obrigações legais: podemos divulgar suas informações onde formos legalmente obrigados a fazê-lo para cumprir a lei aplicável, solicitações governamentais, um processo judicial, ordem judicial ou processo legal, como em resposta a uma ordem judicial ou uma intimação (inclusive em resposta às autoridades públicas para atender aos requisitos de segurança nacional ou de aplicação da lei). </p>
  <br>

  <p> Interesses vitais: podemos divulgar suas informações onde acreditamos ser necessário investigar, prevenir ou tomar medidas em relação a possíveis violações de nossas políticas, suspeita de fraude, situações que envolvam ameaças potenciais à segurança de qualquer pessoa e atividades ilegais, ou como prova em litígio em que estamos envolvidos. </p>
  <br>

  <p> Mais especificamente, podemos precisar processar seus dados ou compartilhar suas informações pessoais nas seguintes situações: </p>
  <br>

  <p> Transferências de negócios. Podemos compartilhar ou transferir suas informações em conexão com, ou durante as negociações de qualquer fusão, venda de ativos da empresa, financiamento ou aquisição de todo ou parte de nosso negócio para outra empresa. </p>
  <br>

  <br><h4 class="title is-4"> 4. COMO TRABALHAMOS SEUS LOGINS SOCIAIS? </h4>

  <p> Resumindo: Se você decidir se registrar ou fazer login em nossos serviços usando uma conta de mídia social, podemos ter acesso a certas informações sobre você. </p>
  <br>

  <p> Nosso site oferece a capacidade de registrar-se e fazer login usando os detalhes de sua conta de terceiros nas redes sociais (como login do Facebook ou Google). Quando você decidir fazer isso, receberemos certas informações de perfil sobre você do seu provedor de mídia social. As informações de perfil que recebemos podem variar dependendo do provedor de mídia social em questão, mas geralmente incluem seu nome, endereço de e-mail, lista de amigos, foto do perfil, bem como outras informações que você decida tornar públicas nessa plataforma de mídia social. </p>
  <br>

  <p> Usaremos as informações que recebermos apenas para os fins descritos neste aviso de privacidade ou que sejam de outra forma esclarecidos para você no site relevante. Observe que não controlamos e não somos responsáveis por outros usos de suas informações pessoais por seu provedor de mídia social terceirizado. Recomendamos que você analise seu aviso de privacidade para entender como eles coletam, usam e compartilham suas informações pessoais e como você pode definir suas preferências de privacidade em seus sites e aplicativos. </p>
  <br>

  <br><h4 class="title is-4"> 5. POR QUANTO TEMPO MANTEMOS SUAS INFORMAÇÕES? </h4>

  <p> Resumindo: mantemos suas informações pelo tempo necessário para cumprir os propósitos descritos neste aviso de privacidade, a menos que seja exigido de outra forma por lei. </p>
  <br>

  <p> Manteremos suas informações pessoais apenas pelo tempo necessário para os fins definidos neste aviso de privacidade, a menos que um período de retenção mais longo seja exigido ou permitido por lei (como impostos, contabilidade ou outros requisitos legais). Nenhum propósito neste aviso exigirá que mantenhamos suas informações pessoais por mais tempo do que o período em que os usuários têm uma conta conosco. </p>
  <br>

  <p> Quando não houver necessidade comercial legítima contínua de processar suas informações pessoais, excluiremos ou anonimizamos essas informações ou, se isso não for possível (por exemplo, porque suas informações pessoais foram armazenadas em arquivos de backup), então Iremos armazenar com segurança suas informações pessoais e isolá-las de qualquer processamento posterior até que a exclusão seja possível. </p>
  <br>

  <br><h4 class="title is-4"> 6. COMO MANTENHA SUAS INFORMAÇÕES SEGURAS? </h4>

  <p> Resumindo: Nosso objetivo é proteger suas informações pessoais por meio de um sistema de medidas técnicas e organizacionais de segurança. </p>
  <br>

  <p> Implementamos medidas de segurança técnicas e organizacionais apropriadas, destinadas a proteger a segurança de qualquer informação pessoal que processamos. No entanto, apesar de nossas salvaguardas e esforços para proteger suas informações, nenhuma transmissão eletrônica pela Internet ou tecnologia de armazenamento de informações pode ser garantida como 100% segura, portanto não podemos prometer ou garantir que hackers, cibercriminosos ou terceiros não autorizados não serão capaz de violar nossa segurança e coletar, acessar, roubar ou modificar indevidamente suas informações. Embora façamos o possível para proteger suas informações pessoais, a transmissão de informações pessoais de e para o nosso site é por sua própria conta e risco. Você só deve acessar o site em um ambiente seguro. </p>
  <br>

  <br><h4 class="title is-4"> 7. QUAIS SÃO OS SEUS DIREITOS DE PRIVACIDADE? </h4>

  <p> Resumindo: em algumas regiões, como o Espaço Econômico Europeu, você tem direitos que permitem maior acesso e controle sobre suas informações pessoais. Você pode revisar, alterar ou encerrar sua conta a qualquer momento. </p>
  <br>

  <p> Em algumas regiões (como o Espaço Econômico Europeu), você tem certos direitos de acordo com as leis de proteção de dados aplicáveis. Isso pode incluir o direito (i) de solicitar acesso e obter uma cópia de suas informações pessoais, (ii) solicitar retificação ou apagamento; (iii) para restringir o processamento de suas informações pessoais; e (iv) se aplicável, à portabilidade de dados. Em certas circunstâncias, você também pode ter o direito de se opor ao processamento de suas informações pessoais. Para fazer tal solicitação, por favor, use os detalhes de contato fornecidos abaixo. Iremos considerar e agir de acordo com qualquer solicitação de acordo com as leis de proteção de dados aplicáveis. </p>
  <br>

  <p> Se dependermos do seu consentimento para processar suas informações pessoais, você tem o direito de retirá-lo a qualquer momento. Observe, entretanto, que isso não afetará a legalidade do processamento antes de sua retirada, nem afetará o processamento de suas informações pessoais conduzido com base em motivos de processamento legais que não sejam o consentimento. </p>
  <br>

  <p> Se você é residente no Espaço Econômico Europeu e acredita que estamos processando ilegalmente suas informações pessoais, você também tem o direito de reclamar junto à autoridade supervisoa de proteção de dados local. Você pode encontrar os dados de contato aqui: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm. </p>
  <br>

  <p> Se você é residente na Suíça, os detalhes de contato das autoridades de proteção de dados estão disponíveis aqui: https://www.edoeb.admin.ch/edoeb/en/home.html. </p>
  <br>

  <p> Se você tiver perguntas ou comentários sobre seus direitos de privacidade, envie um e-mail para contato@cartera.com.br. </p>
  <br>

  <p> Informações da conta </p>
  <br>

  <p> Se, a qualquer momento, desejar revisar ou alterar as informações da sua conta ou encerrar a sua conta, você pode: </p>
  <br>
  <p> Entre em contato usando as informações de contato fornecidas. </p>
  <br>
  <p> Mediante sua solicitação para encerrar sua conta, nós desativaremos ou excluiremos sua conta e informações de nossos bancos de dados ativos. No entanto, podemos reter algumas informações em nossos arquivos para evitar fraudes, solucionar problemas, ajudar em quaisquer investigações, fazer cumprir nossos Termos de Uso e / ou cumprir os requisitos legais aplicáveis. </p>
  <br>

  <p> Optar por não receber marketing por e-mail: Você pode cancelar a inscrição de nossa lista de e-mail de marketing a qualquer momento clicando no link cancelar inscrição nos e-mails que enviamos ou entrando em contato conosco usando os detalhes fornecidos abaixo. Você será então removido da lista de e-mail de marketing - no entanto, ainda podemos nos comunicar com você, por exemplo, para enviar e-mails relacionados ao serviço que são necessários para a administração e uso de sua conta, para responder a solicitações de serviço ou outros fins não relacionados a marketing. Caso contrário, você pode: </p>
  <br>
  <p> Entre em contato usando as informações de contato fornecidas. </p>
  <br>

  <br><h4 class="title is-4"> 8. CONTROLES PARA RECURSOS DE NÃO RASTREAR </h4>

  <p> A maioria dos navegadores da web e alguns sistemas operacionais e aplicativos móveis incluem um recurso Do-Not-Track ("DNT") ou configuração que você pode ativar para sinalizar sua preferência de privacidade de não ter dados sobre suas atividades de navegação online monitorados e coletados. Neste estágio, nenhum padrão de tecnologia uniforme para reconhecimento e implementação de sinais DNT foi finalizado. Como tal, atualmente não respondemos aos sinais do navegador DNT ou a qualquer outro mecanismo que comunique automaticamente a sua escolha de não ser rastreado online. Se for adotado um padrão para rastreamento online que devemos seguir no futuro, iremos informá-lo sobre essa prática em uma versão revisada deste aviso de privacidade. </p>
  <br>

  <br><h4 class="title is-4"> 9. OS RESIDENTES DA CALIFÓRNIA TÊM DIREITOS DE PRIVACIDADE ESPECÍFICOS? </h4>

  <p> Resumindo: Sim, se você for residente na Califórnia, terá direitos específicos relacionados ao acesso às suas informações pessoais. </p>
  <br>

  <p> A seção 1798.83 do Código Civil da Califórnia, também conhecida como lei "Shine The Light", permite que nossos usuários residentes na Califórnia solicitem e obtenham de nós, uma vez por ano e gratuitamente, informações sobre categorias de informações pessoais (se qualquer) divulgamos a terceiros para fins de marketing direto e os nomes e endereços de todos os terceiros com os quais compartilhamos informações pessoais no ano civil imediatamente anterior. Se você é residente na Califórnia e gostaria de fazer tal solicitação, envie sua solicitação por escrito para nós, usando as informações de contato fornecidas abaixo. </p>
  <br>

  <p> Se você tem menos de 18 anos, reside na Califórnia e possui uma conta registrada no site, tem o direito de solicitar a remoção de dados indesejados que postar publicamente no site. Para solicitar a remoção de tais dados, entre em contato conosco usando as informações de contato fornecidas abaixo e inclua o endereço de e-mail associado à sua conta e uma declaração de que você reside na Califórnia. Garantiremos que os dados não sejam exibidos publicamente no site, mas esteja ciente de que os dados podem não ser removidos de forma completa ou abrangente de todos os nossos sistemas (por exemplo, backups, etc.). </p>
  <br>

  <p> Aviso de privacidade da CCPA </p>
  <br>

  <p> O Código de Regulamentos da Califórnia define um "residente" como: </p>
  <br>

  <p> (1) todo indivíduo que está no estado da Califórnia para fins que não sejam temporários ou transitórios e </p>
  <br>
  <p> (2) todo indivíduo domiciliado no estado da Califórnia fora do estado da Califórnia para fins temporários ou transitórios </p>
  <br>

  <p> Todos os outros indivíduos são definidos como "não residentes". </p>
  <br>

  <p> Se esta definição de "residente" se aplica a você, devemos cumprir certos direitos e obrigações em relação às suas informações pessoais. </p>
  <br>

  <p> Quais categorias de informações pessoais coletamos? </p>
  <br>

  <p> Coletamos as seguintes categorias de informações pessoais nos últimos doze (12) meses: </p>
  <br>

  <p>
  A. Identificadores
  Detalhes de contato, como nome real, apelido, endereço postal, número de telefone ou celular, identificador pessoal exclusivo, identificador online, endereço de protocolo de Internet, endereço de e-mail e nome da conta

  </p>
  <br>
  <p> B. Categorias de informações pessoais listadas no estatuto de Registros de Clientes da Califórnia
  Nome, informações de contato, educação, emprego, histórico de empregos e informações financeiras

  </p>
  <br>
  <p> H. Informações de áudio, eletrônicas, visuais, térmicas, olfativas ou semelhantes
  Imagens e gravações de áudio, vídeo ou chamadas criadas em conexão com nossas atividades comerciais

  </p>
  <br>

  <p> NUNCA coletamos as seguintes categorias de informações pessoais: </p>
  <br>

  <p> C. Características de classificação protegidas pela legislação federal ou da Califórnia
  Sexo e data de nascimento
  </p>
  <br>

  <p> D. Informações comerciais
  Informações de transações, histórico de compras, detalhes financeiros e informações de pagamento
  </p>
  <br>

  <p> E. Informações biométricas
  Impressões digitais e de voz
  </p>
  <br>

  <p> F. Internet ou outra atividade de rede semelhante
  Histórico de navegação, histórico de pesquisa, comportamento online, dados de interesse e interações com o nosso e outros sites, aplicativos, sistemas e anúncios
  </p>
  <br>

  <p> G. Dados de geolocalização
  Localização do dispositivo
  </p>
  <br>

  <p> I. Informações profissionais ou relacionadas com o emprego
  Detalhes de contacto comercial para lhe fornecer os nossos serviços a nível empresarial, título de trabalho, bem como histórico de trabalho e qualificações profissionais, se se candidatar a um emprego connosco
  </p>
  <br>

  <p> J. Informações sobre educação
  Registros de alunos e informações de diretório
  </p>
  <br>

  <p> K. Inferências extraídas de outras informações pessoais
  Inferências extraídas de qualquer uma das informações pessoais coletadas listadas acima para criar um perfil ou resumo sobre, por exemplo, as preferências e características de um indivíduo
  </p>
  <br>

  <br><h4 class="title is-4"> 10. FAZEMOS ATUALIZAÇÕES PARA ESTE AVISO? </h4>

  <p> Resumindo: Sim, atualizaremos este aviso conforme necessário para ficar em conformidade com as leis relevantes. </p>
  <br>

  <p> Podemos atualizar este aviso de privacidade de tempos em tempos. A versão atualizada será indicada por uma data "Revisada" atualizada e a versão atualizada entrará em vigor assim que estiver acessível. Se fizermos alterações materiais a este aviso de privacidade, podemos notificá-lo publicando em destaque um aviso de tais alterações ou enviando-lhe diretamente uma notificação. Nós o encorajamos a revisar este aviso de privacidade freqüentemente para ser informado sobre como estamos protegendo suas informações. </p>
  <br>

  <br><h4 class="title is-4"> 11. COMO VOCÊ PODE NOS CONTATAR SOBRE ESTE AVISO? </h4>

  <p> Se você tiver perguntas ou comentários sobre este aviso, pode nos enviar um e-mail para contato@cartera.com.br ou por correio para: </p>
  <br>

  <b-card bg-variant = "light" no-body class = "px-3 pt-3">
  <b-card-title> Cartera </b-card-title>
  <b-card-body class = "p-0">
  <p> R. Deputado Antonio Edu Vieira, 1422, Apto 1024. </p>
  <br>
  <p> Florianópolis, SC, Brasil </p>
  <br>
  </b-card-body>
  </b-card>

  <br><h4 class="title is-4"> 12. COMO VOCÊ PODE REVER, ATUALIZAR OU EXCLUIR OS DADOS QUE COLETAMOS DE VOCÊ? </h4>

  <p> Com base nas leis aplicáveis do seu país, você pode ter o direito de solicitar acesso às informações pessoais que coletamos de você, alterar essas informações ou excluí-las em algumas circunstâncias. Para solicitar a revisão, atualização ou exclusão de suas informações pessoais, mande um email a contato@cartera.com.br. Responderemos à sua solicitação em 30 dias. </p>
  <br>
  </div>
</template>
